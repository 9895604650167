.siteFooter {
	background: #f9f9fb;
	padding: 30px 20px 10px 20px;
	margin-top: 50px;
}

.footerBlocks {
	gap: 30px;
}

.footerBlocks .footerBlock {
	width: calc(50% - 15px);
	float: left;
}

.footerTitle {
	margin-bottom: 15px;
	font-weight: 700;
	font-size: 15px;
	color: #000;
}

.footerMenu li a {
	display: inline-block;
	padding: 4px 0;
	color: #000;
	font-size: 14px;
}

.footerMenu li svg {
	height: 20px;
	width: 20px;
	display: inline-block;
	margin-right: 10px;
}

.footerIconLink {
	gap: 10px;
}

.footerSection {
	margin-top: 15px;
	padding: 15px;
	border-top: 1px solid #e8e8e1;
}

/* Desktop CSS */
.footerContainer {
	margin-top: 20px;
	background-color: white;
}
.imgBoxText .imgBoxTitle {
	font-size: 18px;
}
.imgBoxText .imgBoxDesc {
	font-size: 13px;
}
.imgBoxImg {
	min-height: 95px;
}
.imgBoxImg svg{
	width: 80px;
}
.footerTabCol h5 {
	color: black;
	font-weight: bold;
	font-size: 14px;
}
.footerTabCol ul li a {
	color: #555;
	font-size: 14px;
}
.copyright p {
	font-size: 14px;
}
.socialIcon svg{
	height: 15px;
	width: 15px;
}
.whatsAppLink{
	color: #111;
	font-size: 14px;
}