.singleFeaturedProduct {
  border: 1px solid #ddd;
  padding: 10px 12px 45px;
  background: #fff;
  border-radius: 10px;
}
.featureOffBox {
  background: #389956;
  color: #fff;
  font-weight: 600;
  border-radius: 2px;
  font-size: 11px;
  line-height: 10px;
  padding: 4px 3px;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.featuredImageBox {
  /* padding-top: 100%; */
  border-radius: 5px;
}
.featuredImageBox img {
  top: 0;
  left: 0;
  object-fit: cover;
}
.soldOutText {
  background: #ed1f29;
  -webkit-transform: rotate(-5deg) translateY(-50%);
  transform: rotate(-5deg) translateY(-50%);
  border-radius: 4px;
  top: 50%;
  left: 0px;
  right: 0px;
  margin: auto;
  max-width: 100px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 4px 5px;
  z-index: 2;
}
.soldOutText::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 3px;
  border: 1px solid #fff;
  display: inline-block;
}
.offerItemName {
  font-size: 15px;
  line-height: 17px;
  color: #212121;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  white-space: normal;
}
.offerPrice {
  font-size: 16px;
  line-height: 16px;
  color: #16253b;
}
.offerDiscountPrice {
  font-size: 13px;
  color: #16253b;
  opacity: 0.8;
  line-height: 14px;
}
.addCartBtn {
  background: var(--ADD_BUTTON_COLOR);
  border: none;
  padding: 0 15px;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  height: 35px;
  bottom: 10px;
  right: 10px;
  left: 10px;
}
.itemQuantityBtnBox {
  bottom: 10px;
  right: 15px;
  z-index: 9;
}
.itemQuantityBtnBox button {
  background: transparent;
  box-shadow: none;
  border: none;
}
.itemQuantityBtn .decreaseBtn {
  width: 20px;
}
.itemQuantityBtn {
  padding-right: 0;
  padding-left: 0;
  margin-top: auto;
  margin-bottom: 0px;
  position: relative;
  z-index: 9;
}
.countValue {
  width: 20px;
  text-align: center;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: transparent;
  cursor: default;
  font-weight: 500;
}
.decrease_btn,
.increase_btn {
  height: 20px;
  width: 20px;
  border: 1px solid var(--ADD_BUTTON_COLOR);
  color: var(--ADD_BUTTON_COLOR);
  background: #fff;
  border-radius: 20px;
  font-size: 20px;
  padding-bottom: 5px;
}
.galleryImage {
  opacity: 0;
  transition: all ease 200ms;
}
.featuredImageBox:hover .galleryImage {
  opacity: 1;
}

.productImgContainer {
  height: 150px;
}

.productImg {
  height: auto;
  max-height: 150px;
  max-width: 100%;
  width: auto;
  margin: auto;
}

/* Desktop CSS */
@media screen and (min-width: 568px) {
  .productImgContainer {
    height: 250px;
  }

  .productImg {
    height: auto;
    max-height: 250px;
  }

  .offerItemName {
    min-height: 34px;
  }
  .addCartBtn {
    font-size: 15px;
    line-height: 15px;
    height: 45px;
  }
  .singleFeaturedProduct {
    padding-bottom: 60px;
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .singleFeaturedProduct:hover{
    transform: scale(0.95);
  }

  .countValue {
    width: 40px;
  }
  .decrease_btn,
  .increase_btn {
    height: 30px;
    width: 30px;
  }
}
