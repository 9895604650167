.sitemap {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.sitemap h1 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

h2{
  font-size: 24px;
  font-weight: bold;
  margin: 5px;
}

.sitemap-section {
  margin-bottom: 20px;
}

.sitemap-section h2 {
  margin-bottom: 10px;
}

h3{
  font-size: 20px;
  margin: 5px;
}

h3 a{
  font-weight: 600;
  text-decoration: none;
}

.sitemap-section ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sitemap-section ul li {
  margin: 10px;
}

.sitemap-section ul li a {
  text-decoration: none;
  color: #007bff;
}

.sitemap-section ul li a:hover {
  text-decoration: underline;
}
