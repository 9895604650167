.categoryBox{
    background: #FFF;
    border-radius: 10px;
}
.exploreByCategoryHeader {
    font-weight: bold;
    color: #1D2A39;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}
.categoryblock{
    float: left;
    width: 50%;
    padding: 0px 10px;
}
.imgBox{
    border-radius: 100%;
    border: 1px solid var(--PRIMARY_COLOR);
}
.categoryProdName{
    color: #000;
    font-weight: 500;
    font-size: 13px;
}