.exploreByCategoryHeader {
    font-weight: bold;
    color: #1D2A39;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}
.categoryBox{
    border-radius: 10px;
    padding: 10px;
    background: #FFF;
}
.offersBox{
    /* background: yellow; */
    border-radius: 10px;
}
/* Desktop CSS */
.categoryHeaderTitle{
    font-size: 25px;
    font-weight: 700;
}
.bannerContainer img{
    border-radius: 10px;
}

.banner{
    cursor: pointer;
}