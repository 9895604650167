.orderItems{
    font-size: 18px;
    color: #111;
}
.orderBox{
    background: #FFF;
}
.orderLabel{
    font-size: 16px;
    color: #111;
}
.orderImag{
    height: 50px;
    width: 50px;
}
.orderItemName{
    font-size: 12px;
    font-weight: 700;
    color: #111;
}
.itemName{
    font-size: 11px;
    color: #676E8A;
}
.itemPrice{
    font-size: 14px;
    color: #676E8A;
}
.assurityTabs{
    background-color: #E4E9F2;
    color: #676E8A;
    font-size: 11px;
    padding: 3px 6px;
}
.progressTrack .step{
    height: 2px;
    background: rgb(151, 149, 149, 0.651);
    z-index: 1;
}
.progressTrack .stepName{
    font-size: 11px;
    color: #676E8A;
    font-weight: 400;
    top: 8px;
}
.progressTrack .step:before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgb(151, 149, 149);
    border-radius: 50%;
    top: -4px;
    z-index: 2;
}
.step1:before{
    left: 0;
}
.step2:before{
    left: 50%;
    transform: translateX(-50%);
}
.step3:before{
    left: 50%;
    transform: translateX(-50%);
}
.step4:before{
    right: 0;
}
.progressTrack .step.stepActive, .progressTrack .step.stepActive::before{
    background: #CF102E;
}
.progressTrack .step.stepActive+.step:after{
    content: '';
    left: 0px;
    width: 50%;
    top: 0;
    height: 2px;
    display: inline-block;
    position: absolute;
    background: #CF102E;
}
.progressTrack .step.stepActive .stepName{
    color: #000;
    font-weight: 500;
}
.reSchduleBtn {
    border: 1px solid #DD5E5E;
    color: #DD5E5E;
    height: 30px;
    border-radius: 2px;
    background-color: white;
    font-weight: bold;
    font-size: 13px;
}