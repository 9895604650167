.gradientMenuDrawer{
    transform: translate(-100%);
    -webkit-transform: translate(-100%);
    visibility: hidden;
    z-index: 9999;
    left: 0;
    top: 0%;
    width: 100%;
	transition: all ease 400ms;
	-webkit-transition: all ease 400ms;
}
.gradientMenuDrawer:after{
	content: '';
	position: fixed;
	z-index: 0;
	background: rgba(0,0,0,0.80);
	inset: 0;
	display: inline-block;
}
.gradientMenuDrawer.openDrawer{
	transform: translate(0%);
    -webkit-transform: translate(0%);
	visibility: visible;
}
.rightDrwaerClose{
    height: 40px;
    width: 40px;
    padding: 10px;
    right: 0;
    top: 10px;
	z-index: 3;
	color: #000;
}
.rightDrwaerClose svg{
	height: 20px;
	width: 20px;
	fill: currentColor;
}
.loggedName{
	font-weight: 700;
	color: var(--PRIMARY_COLOR);
	font-size: 16px;
}
.menuDrawerInnerContainer{
	background: #FFF;
	inset: 0;
    width: 85%;
    z-index: 2;
    max-width: 350px;
}
.menuDrawerNavigationContainer{
	float: left;
	width: 100%;
	padding: 0px 20px 30px 0px;
    height: calc(100vh - 280px);
    overflow-y: auto;
}
.menuDrawerNavigation ul li{
	margin-bottom: 5px;
}
.menuDrawerNavigation>ul>li, .menuDrawerNavigation ul li .menuDrawerMenuItem{
	min-height: 45px;
	float: left;
	width: 100%;
	border-radius: 5px;
	color: #111;
}
.menuDrawerNavigation ul li .menuDrawerMenuItem{
	padding: 10px 10px 10px 30px;
    gap: 20px;
}
.menuDrawerNavigation ul li svg{
	height: 18px;
	width: 18px;
	object-fit: contain;
	fill: currentColor;
}
.menuDrawerNavigation ul li .menuDrawerMenuItem.activeTab{
	background: var(--MENU_HOVER_COLOR);
	color: var;
	border-radius: 0px 30px 30px 0px;
}
.menuDrawerNavigation ul li .menu-text{
	font-size: 16px;
	color: inherit;
}
.menuDrawerNavigation ul li .menuDrawerMenuItem.activeTab .menu-text{
	font-weight: 700;
	color: inherit;
}
.menuDrawerNavigation ul li .menuDrawerchildItem svg{
	height: 15px;
	width: 15px;
	transition: all ease 400ms;
	-webkit-transition: all ease 400ms;
}
.menuDrawerchildItem.active svg.menuArrow{
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}
.menuDrawerNavigationContainer .loginSignup{
	padding: 10px 30px;
	height: 70px;
	color: #000;
	font-size: 16px;
	gap: 0px;
    border-bottom: 1px solid #e8e8e1;
    margin-bottom: 10px;
}
.loginSignup .iconAccount{
	height: 20px;
	width: 20px;
}
.loginSignup .loggedName{
	font-weight: 700;
	color: var(--PRIMARY_COLOR);
	font-size: 16px;
}
.loginSignup .loginText{
	font-weight: 600;
	color: #111;
	font-size: 14px;
}
.menuDrawerNavigation .categoryImgBox{
	padding-top: 100%;
	border: 2px solid #F9F01F;
	border-radius: 5px;
}
.menuCategoryblock{
	gap: 15px;
	background: #FFF;
	border-radius: 5px;
}
.categoryImgBox .listCatImg{
	inset: 0;
	z-index: 1;
}
.categoryName{
	font-size: 12px;
	color: #000;
	font-weight: 600;
	bottom: 8px;
	left: 5px;
	right: 5px;
	border-radius: 3px;
	padding: 2px 5px;
	background: #FFF;
	z-index: 5;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.submenuList{
	background: rgba(207,16,46,0.12);
    border-radius: 0 25px 25px 0;
}
.submenuList .submenuListBox{
	float: left;
}
.menuDrawerUtilityLinks{
	background: #EEE;
	padding: 10px 25px;
}
.listSocialTitle{
	font-size: 16px;
	color: #000;
	font-weight: 600;
}
.listSocialLink a{
	color: #000;
}
.listSocial{
	gap: 10px;
}
.listSocialLink svg{
	height: 20px;
	width: 20px;
	fill: #000;
}
.footerMenu li svg{
	height: 20px;
	width: 20px;
	display: inline-block;
	margin-right: 10px;
}
.footerMenu li a {
    display: inline-block;
    padding: 4px 0;
    color: #000;
    font-size: 14px;
}