
.shopAgeContainer .ageBlock{
	width: calc(50% - 10px);
}

.ageBlock{
	cursor: pointer;
}

.ageBlock:hover .shopAgeNumber{
	font-weight: bold;
}
.ageBlock img {
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ageBlock:hover img {
    transform: scale(1.4);
    cursor: pointer;
}

.ageBlockIcon{
	padding-top: 100%;
	border-radius: 100%;
	background: #FFF;
}
.ageBlockIcon img{
	inset: 0;
	border-radius: 100%;
}
.shopAgeContainer{
	background: var(--MENU_HOVER_COLOR);
	border-radius: 10px;
}
.shopAgeNumber{
	font-size: 14px;
	margin-top: 10px;
	color: #FFF;
	font-weight: 500;
}
.shopAgeBox .exploreByCategoryHeader{
	color: #FFF;
	font-size: 16px;
	font-weight: 700;
}
.scrollAgeBox{
	gap: 20px;
}
/* Desktop CSS */
.categoryHeaderTitle{
    font-size: 25px;
    font-weight: 700;
}
@media screen and (min-width:568px) {
	.shopAgeContainer .ageBlock{
		width: calc(25% - 30px);
	}
	.shopAgeContainer{
		padding: 30px 20px 20px 20px;
	}
	.scrollAgeBox{
		gap: 30px;
	}
	.shopAgeNumber{
		font-size: 17px;
	}
}