.addressTab{
    border-radius: 6px;
    border-left: 6px solid var(--PRIMARY_COLOR);
    padding: 0 10px;
    height: 60px;
    background: #FFF;
}
.addAressTxt{
    font-size: 15px;
    color: #000;
    font-weight: 500;
}
.addAressIcon{
    color: var(--PRIMARY_COLOR);
    font-size: 30px;
    font-weight: 700;
    line-height: 14px;
}
.savedAddress{
    font-size: 17px;
    font-weight: 700;
    color: #000;
}
.addressCnt{
    font-weight: 400;
}