.loginFormFloating{
    margin-bottom: 15px;
}
.formInput {
    height: 50px;
    padding: 12px 40px 12px 12px;
    border-radius: 6px;
    transition: .5s;
    border: 1px solid #DDD;
    background: transparent;
    position: relative;
    z-index: 2
}

.formInput:focus,.formInput:focus-visible,.formInput:not(:placeholder-shown) {
    border: 1px solid var(--PRIMARY_COLOR);
    outline: none
}
.formInput::placeholder {
    color: transparent
}
.formLabel {
    left: 12px;
    top: 12px;
    padding: 0 10px;
    background: #FFF;
    font-size: 15px;
    transition: .2s
}
.required {
    color: #FF0000;
    font-size: 10px;
    margin-right: 5px;
    font-weight: 500
}
.formInput:focus+.formLabel,.formInput:not(:placeholder-shown)+.formLabel {
    transform: translateY(-20px) translateX(0);
    -webkit-transform: translateY(-20px) translateX(0);
    font-size: 10px;
    color: #111;
    z-index: 3
}
.formInput:not(:placeholder-shown) + .formLabelDisabled {
    background: linear-gradient(0deg, #F0F0F0 30%, white 50%) !important;
}
.addressFrom{
    background: #FFF;
}
.savedAddress{
    font-size: 17px;
    font-weight: 700;
}
.saveAddrsBtn{
    background: var(--PRIMARY_COLOR);
    height: 60px;
    font-size: 18px;
    color: #FFF;
}
.customRadio{
    border: 1px solid var(--ADD_BUTTON_COLOR);
    height: 20px;
    width: 20px;
    border-radius: 50%;
}
.address_option:checked+.customRadio:after{
    margin: auto;
    content: '';
    height: 14px;
    width: 14px;
    display: block;
    border-radius: 50%;
    background-color: var(--ADD_BUTTON_COLOR);
    position: absolute;
    inset: 0;
}
.addressOptionBox{
    font-size: 15px;
    color: #111;
}
.addressOptionBox .address_option{
    display: none;
}